import React, { useState, useEffect, useMemo, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import TextareaAutosize from 'react-textarea-autosize'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { FaPlus, FaRegTrashAlt } from 'react-icons/fa'
import { LuGripVertical } from 'react-icons/lu'
import { setUserWriterSelections, selectDocumentState } from '../../../redux/docSlice'
import { DocumentState } from '../../../redux/types'
import useNumSubtopics from '../../../hooks/numSubtopics'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../../hooks/featureFlags'
import { useDocId } from '../../../hooks/docID'

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}

const Subtopic = memo(function Subtopic({
	subtopic,
	index,
	isIntroOrConclusion,
	invalidSubtopics,
	handleChange,
	handleRemove,
	draggableProvided,
	value,
}: {
	subtopic: { id: string; text: string }
	index: number
	isIntroOrConclusion: boolean
	invalidSubtopics: boolean
	handleChange: (idx: number, newValue: string) => void
	handleRemove: (idx: number) => void
	draggableProvided?: any
	value?: string
}) {
	const docID = useDocId()
	const reorderSubtopicsFF = useFeatureFlagVariantKey(FEATURE_FLAGS.REORDER_SUBTOPICS)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const { minNumberOfSubtopics } = useNumSubtopics()

	const reduxSubtopics = documentState.userWriterSelections.subTopics

	return (
		<div className="flex gap-1 items-center">
			{/* The drag handle icon on the left (hide if intro/conclusion) */}
			{reorderSubtopicsFF === 'test' && draggableProvided && !isIntroOrConclusion && (
				<div
					{...draggableProvided.dragHandleProps}
					className="cursor-grab text-gray-500 hover:text-gray-700"
					style={{ minWidth: '1.5rem', textAlign: 'center' }}
				>
					<LuGripVertical size={18} />
				</div>
			)}

			<div className="flex flex-col w-full gap-1">
				<TextareaAutosize
					placeholder="Type a subtopic here..."
					className={`w-full py-2 px-3 md:text-2xl text-xl border resize-none outline-none border-gray-400 ${
						invalidSubtopics && subtopic.text.length === 0
							? 'border-danger-default'
							: isIntroOrConclusion
							? 'bg-gray-100'
							: ''
					}`}
					disabled={isIntroOrConclusion}
					value={value ?? subtopic.text}
					onChange={(e) => handleChange(index, e.target.value)}
					minRows={1}
				/>
				{/* Validation message if blank */}
				{invalidSubtopics && subtopic.text.length === 0 && (
					<div className="text-danger-default text-sm">Please enter a subtopic</div>
				)}
			</div>

			{!isIntroOrConclusion && minNumberOfSubtopics < reduxSubtopics.length - 2 && (
				<FaRegTrashAlt
					className="text-gray-500 hover:text-primary cursor-pointer pl-2 w-8"
					onClick={() => handleRemove(index)}
				/>
			)}
		</div>
	)
})

export function SubtopicsInput({ docID }: { docID: string }) {
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const { minNumberOfSubtopics, maxNumberOfSubtopics } = useNumSubtopics()
	const reduxSubtopics = documentState.userWriterSelections.subTopics
	const [localSubtopics, setLocalSubtopics] = useState(() => reduxSubtopics.map((text) => ({ id: uuidv4(), text })))

	useEffect(() => {
		if (reduxSubtopics.length !== localSubtopics.length) {
			// Regenerate local state with stable IDs for any new items
			setLocalSubtopics((prev) => {
				// If the array got bigger, add new items
				if (reduxSubtopics.length > prev.length) {
					// How many to add?
					const diff = reduxSubtopics.length - prev.length
					const newItems = reduxSubtopics.slice(-diff).map((text) => ({
						id: uuidv4(),
						text,
					}))
					return [...prev.slice(0, -1), ...newItems] // example logic
				}
				// If array got smaller, or there's some big external change,
				// fallback: re-map everything (this might cause ID changes).
				return reduxSubtopics.map((text) => ({ id: uuidv4(), text }))
			})
		} else {
			// If same length, maybe text changed; update text only
			setLocalSubtopics((prev) =>
				prev.map((item, i) => {
					if (reduxSubtopics[i] !== item.text) {
						return { ...item, text: reduxSubtopics[i] }
					}
					return item
				})
			)
		}
	}, [reduxSubtopics, localSubtopics.length])

	// 3) Validate how many subtopics are non-empty, ignoring first & last
	const invalidSubtopics = useMemo(() => {
		return localSubtopics.filter((subtopic) => subtopic?.text.length > 0).length - 2 < minNumberOfSubtopics
	}, [localSubtopics, minNumberOfSubtopics])

	/**
	 * Updates local subtopic text at index, and dispatches new strings to Redux.
	 */
	function handleChange(index: number, newValue: string) {
		setLocalSubtopics((prev) => {
			const updated = [...prev]
			updated[index] = { ...updated[index], text: newValue }
			// Also update Redux
			dispatch(
				setUserWriterSelections({
					docID,
					userWriterSelections: {
						...documentState.userWriterSelections,
						subTopics: updated.map((item) => item.text),
					},
				})
			)
			return updated
		})
	}

	/**
	 * Remove subtopic at index from local state & from Redux.
	 */
	function handleRemove(index: number) {
		setLocalSubtopics((prev) => {
			const updated = prev.filter((_, i) => i !== index)
			// Also update Redux
			dispatch(
				setUserWriterSelections({
					docID,
					userWriterSelections: {
						...documentState.userWriterSelections,
						subTopics: updated.map((item) => item.text),
					},
				})
			)
			return updated
		})
	}

	/**
	 * Reorder subtopics in local state, then dispatch to Redux.
	 */
	function handleDragEnd(result: DropResult) {
		if (!result.destination) return
		// Disallow dragging intro (0) or conclusion (last).
		if (result.source.index === 0 || result.source.index === localSubtopics.length - 1) return
		if (result.destination.index === 0 || result.destination.index === localSubtopics.length - 1) return

		setLocalSubtopics((prev) => {
			const reordered = reorder(prev, result.source.index, result?.destination?.index ?? 0)
			// Update Redux as just strings
			dispatch(
				setUserWriterSelections({
					docID,
					userWriterSelections: {
						...documentState.userWriterSelections,
						subTopics: reordered.map((item) => item.text),
					},
				})
			)
			return reordered
		})
	}

	/**
	 * Insert a new empty subtopic before the last (conclusion).
	 */
	function addNewSubtopic() {
		setLocalSubtopics((prev) => {
			// Insert a new object before the last item
			const updated = [...prev.slice(0, -1), { id: uuidv4(), text: '' }, prev[prev.length - 1]]
			// Also update Redux
			dispatch(
				setUserWriterSelections({
					docID,
					userWriterSelections: {
						...documentState.userWriterSelections,
						subTopics: updated.map((item) => item?.text ?? ''),
					},
				})
			)
			return updated
		})
	}

	return (
		<div className="flex flex-col gap-3">
			{/* Intro subtopic (index 0, disabled) */}
			{localSubtopics[0] && (
				<Subtopic
					subtopic={localSubtopics[0]}
					index={0}
					isIntroOrConclusion={true}
					invalidSubtopics={invalidSubtopics}
					handleChange={handleChange}
					handleRemove={handleRemove}
					value="Introduction"
				/>
			)}

			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="subtopics">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{localSubtopics.map((subtopic, index) => {
								const isIntroOrConclusion = index === 0 || index === localSubtopics.length - 1

								// Skip rendering the first & last subtopic here (they’re outside the Droppable)
								if (isIntroOrConclusion) return null

								return (
									<Draggable
										key={subtopic.id} // stable ID
										draggableId={subtopic.id}
										index={index}
										isDragDisabled={isIntroOrConclusion}
									>
										{(dragProvided) => (
											<div ref={dragProvided.innerRef} {...dragProvided.draggableProps} className="py-3">
												{subtopic && (
													<Subtopic
														subtopic={subtopic}
														index={index}
														isIntroOrConclusion={isIntroOrConclusion}
														invalidSubtopics={invalidSubtopics}
														handleChange={handleChange}
														handleRemove={handleRemove}
														draggableProvided={dragProvided}
													/>
												)}
											</div>
										)}
									</Draggable>
								)
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>

			{/* Button to add a new subtopic */}
			{localSubtopics.length - 2 < maxNumberOfSubtopics && (
				<div
					className="flex justify-center items-center gap-2 border border-dotted border-gray-600 py-2
                     hover:bg-gray-100 hover:border-solid cursor-pointer hover:text-black"
					onClick={addNewSubtopic}
				>
					<FaPlus />
					<div className="text-xl">Add Subtopic</div>
				</div>
			)}

			{/* Conclusion subtopic (index last, disabled) */}
			{localSubtopics[localSubtopics.length - 1] && (
				<Subtopic
					subtopic={localSubtopics[localSubtopics.length - 1]}
					index={localSubtopics.length - 1}
					isIntroOrConclusion={true}
					invalidSubtopics={invalidSubtopics}
					handleChange={handleChange}
					handleRemove={handleRemove}
					value="Conclusion"
				/>
			)}
		</div>
	)
}
