import { Paddle } from '@paddle/paddle-js'

export type ProcessedChunkResponse = {
	buffer: string
	parsed: any[]
}

export enum REF_STYLES {
	APA_7 = 'APA 7',
	MLA_9 = 'MLA 9',
	HARVARD = 'Harvard',
	IEEE = 'IEEE',
	CHICAGO = 'Chicago',
}

export enum USE_CASE {
	PRE_UNIVERSITY = 'PRE_UNIVERSITY',
	UNDERGRADUATE = 'UNDERGRADUATE',
	GRADUATE = 'GRADUATE',
	PROFESSIONAL = 'PROFESSIONAL',
}

export type UserWriterSelections = {
	wordCount: number
	refStyle: REF_STYLES | null
	tone: string | null
	type: string
	readingLevel: string | null
	humanizerIntensity: string
	subTopics: string[]
	thesisStatement: {
		text: string
		active: boolean
	}
	writingStyle: {
		generalSelected: boolean
		referenceText?: string
		description?: string
		longDescription?: string
		sampleText?: string
	}
}

export type ReferencesInfo = {
	active: boolean
	title?: string
	authors?: string[]
	authorsString?: string
	page?: string
	publisher?: string
	url?: string
	publishedDate?: string
	custom?: boolean
	content?: string
}

export type DocumentState = {
	setup: boolean
	setupChanged: boolean
	content: string
	drafts: {
		content: string
		selected: boolean
	}[]
	jsonContent: any //JSON representation of content, used for pdf exports
	title: string
	humanized: boolean | null
	lastSavedContent: string
	lastSavedTitle: string
	userWriterSelections: UserWriterSelections
	isGenerating: boolean //Waiting on response from server
	isGeneratingSubtopics: boolean //Waiting on response from server
	isTypingAddMore: boolean //Waiting for addmore to finish typing
	isTypingEssay: boolean //Waiting for essay to finish typing
	needsHumanizing: boolean //Needs to be humanized
	isHumanizing: boolean //Waiting for humanizer to finish
	isOnSubtopicsStep: boolean //Is on subtopics step
	references: ReferencesInfo[]
	allReferences: ReferencesInfo[]
	generatingReferences: boolean
}

export type Documents = {
	[docID: string]: DocumentState
}

export type SystemState = {
	showNav: boolean
	lastAggregatePageVisited: string
	user: any
	userLoading: boolean
	userPagesCache: any
	userPagesLoading: boolean
	tiptapJWToken?: string
	paddle?: Paddle
	graderModeOn: boolean
	selectedGraderCategory: 'grammar' | 'tone' | 'content'
	categoryScoresLoading: boolean
	numAcceptedSuggestions: number
}
