import { Node } from '@tiptap/pm/model'
import { Editor } from '@tiptap/core'
import { useCallback, useState } from 'react'

export const useData = () => {
	const [currentNode, setCurrentNode] = useState<Node | null>(null)
	const [currentNodePos, setCurrentNodePos] = useState<number>(-1)

	const handleNodeChange = useCallback(
		(data: { node: Node | null; editor: Editor; pos: number }) => {
			if (data.node) {
				setCurrentNode(data.node)
			}

			setCurrentNodePos(data.pos)
		},
		[setCurrentNodePos, setCurrentNode]
	)

	return {
		currentNode,
		currentNodePos,
		setCurrentNode,
		setCurrentNodePos,
		handleNodeChange,
	}
}
