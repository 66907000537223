import React, { Suspense, lazy, useEffect } from 'react'
import store from './redux/store'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar'
import { CONSTANTS } from './constants'
import { CookiesProvider } from 'react-cookie'
import { AuthProvider, useAuth } from './contexts/Auth'
import PrivateRoute from './Pages/PrivateRoute'
import Create from './Pages/Create/Create'
import Examples from './Pages/Examples'
import Post from './Pages/Post'
import Gallery from './Pages/Choose'
import Blog from './Pages/Blog'
import Unknown from './Pages/404'
import New from './Pages/New'
import { HelmetProvider } from 'react-helmet-async'
import Pricing from './Pages/Pricing'
import { PostHogProvider, useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { selectUser, setPaddle } from './redux/systemSlice'
import { useCookieUser } from './hooks/cookieUser'
import { FEATURE_FLAGS } from './hooks/featureFlags'
import Thanks from './Components/Modals/Thanks'
import useUniqueId from './hooks/uniqueId'
import Billing from './Pages/Billing'
import { initializePaddle, Paddle } from '@paddle/paddle-js'
import HumanizerPage from './Pages/Humanizer'
import DetectorPage from './Pages/Detector'
import useIsPremium from './hooks/useIsPremium'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export function getCookie(cname: string) {
	let name = cname + '='
	let decodedCookie = decodeURIComponent(document.cookie)
	let ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

export const contactFormLink = 'https://the-good-ai.helpscoutdocs.com'
const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
	autocapture: false,
}

function App() {
	return (
		<React.StrictMode>
			<PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string} options={options}>
				<Provider store={store}>
					<AuthProvider>
						<CookiesProvider>
							<HelmetProvider>
								<ToastContainer toastClassName="rounded-none text-gray-900" />
								<Routes />
							</HelmetProvider>
						</CookiesProvider>
					</AuthProvider>
				</Provider>
			</PostHogProvider>
		</React.StrictMode>
	)
}

const Home = lazy(() => import('./Pages/Home/Home'))

const Routes = () => {
	const user = useSelector(selectUser)
	const { userIDCookie } = useCookieUser()
	const posthog = usePostHog()
	const dispatch = useDispatch()
	const uniqueId = useUniqueId()
	const isPremium = useIsPremium()
	const { currentUser } = useAuth() as any

	useEffect(() => {
		if (!uniqueId) return
		posthog?.identify(uniqueId, {
			email: user?.email,
			id: user?.id,
			premium: isPremium ? 'true' : 'false',
			stripe_id: user?.stripeId,
			cookie_id: userIDCookie,
			created_at: user?.joined,
			use_case: user?.useCase,
		})
	}, [posthog, user.id, user.email, userIDCookie, uniqueId, isPremium])

	useEffect(() => {
		const updateUserPhid = async () => {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({ user_id: user.id, phid: posthog.get_distinct_id() }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/user/update/', requestOptions)
		}

		if (currentUser && user && user.id && !user.phid && posthog) {
			updateUserPhid()
		}
	}, [user.id, posthog, currentUser])

	useEffect(() => {
		initializePaddle({
			environment: 'production',
			token: 'live_60dfb4de5a030605577558241b3',
			pwCustomer: user?.paddleId,
		}).then((paddleInstance: Paddle | undefined) => {
			if (paddleInstance) {
				dispatch(setPaddle(paddleInstance))
			}
		})
	}, [])

	return (
		<BrowserRouter>
			<Suspense fallback={<div></div>}>
				<Thanks />
				<Navbar />
				<Switch>
					<PrivateRoute
						loginRequired={false}
						component={(props: any) => (
							<Create type={props.match.params.type} docID={props.match.params.id ? atob(props.match.params.id) : ''} />
						)}
						path={CONSTANTS.CREATE_PATH + '/:type/:id'}
					/>
					<PrivateRoute loginRequired={false} path={CONSTANTS.DOC_PATH} component={() => <Gallery />} />
					<Route path="/post/:title" render={(props) => <Post path={props.match.params.title} />} />
					<Route path="/new/:template" render={(props) => <New template={props.match.params.template} />} />
					<Route path="/examples" render={() => <Examples />} />
					<Route path="/blog/:title" render={(props) => <Post path={props.match.params.title} />} />
					<Route path="/blog" render={() => <Blog />} />
					<Route path="/pricing" render={() => <Pricing />} />
					<Route path="/humanizer" render={() => <HumanizerPage />} />
					<Route path="/detector" render={() => <DetectorPage />} />
					<Route path="/billing" render={() => <Billing />} />
					<Route exact path="/" render={() => <Home />} />
					<Route path="/404" component={Unknown} />
					<Redirect to="/404" />
				</Switch>
			</Suspense>
		</BrowserRouter>
	)
}

export default App
