import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../Components/Loader'
import { atom, useAtom } from 'jotai'
import { useAuth } from '../../contexts/Auth'
import {
	createNewDocument,
	setContent,
	setHumanized,
	setLastSavedContent,
	setLastSavedTitle,
	setReferences,
	setSetup,
	setTitle,
} from '../../redux/docSlice'
import { selectUser, selectUserLoading, setLastAggregatePageVisited, setShowNav } from '../../redux/systemSlice'
import Essay from './Essay'
import './_Create.scss'
import * as Sentry from '@sentry/react'
import { useCookieUser } from '../../hooks/cookieUser'
import { DOC_TEMPLATES } from '../../constants'
import UpgradeModals from '../../Components/Modals/Upgrade'
import { ReferencesInfo } from '../../redux/types'

type CreateProps = {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
}

type completeDoc = {
	title: string
	content: string
	setup: boolean
	humanized: boolean | null
	references: ReferencesInfo[]
}

const pageName = 'create'

type ModalAtomSchema = {
	subscriptionModalOpen: boolean
	loginModalOpen: boolean
	upsellModalOpen: boolean
}

const defaultValues: ModalAtomSchema = {
	subscriptionModalOpen: false,
	loginModalOpen: false,
	upsellModalOpen: false,
}

export const accountModalAtom = atom<ModalAtomSchema>(defaultValues)

export const docLoadingAtom = atom(false)

function Create(props: CreateProps) {
	const { userIDCookie } = useCookieUser()
	const [invalid, setInvalid] = useState(false)
	const [loading, setLoading] = useState(true)
	const [fetched, setFetched] = useState(false)
	const [accountModalState, setAccountModalState] = useAtom(accountModalAtom)
	const [, setDocLoading] = useAtom(docLoadingAtom)

	const { currentUser } = useAuth() as any
	const dispatch = useDispatch()
	let userLoading = useSelector(selectUserLoading)
	const user = useSelector(selectUser)

	const getDocument = useCallback(async () => {
		if (!userLoading && !fetched && (userIDCookie || user.id)) {
			// setLoading(true)
			setDocLoading(true)
			const token = currentUser ? await currentUser.getIdToken() : ''
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id: props.docID }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/document/retrieve/', requestOptions)
				.then((res) => res.json())
				.then((doc: completeDoc & { success: boolean }) => {
					setLoading(false)
					dispatch(setShowNav({ value: true }))
					if (doc) {
						setFetched(true)
						dispatch(createNewDocument({ docID: props.docID }))
						dispatch(setSetup({ docID: props.docID, setup: doc.setup }))
						dispatch(setHumanized({ docID: props.docID, humanized: doc.humanized }))
						dispatch(setContent({ docID: props.docID, content: doc.content }))
						dispatch(setTitle({ docID: props.docID, title: doc.title }))
						dispatch(setLastSavedTitle({ docID: props.docID, lastSavedTitle: doc.title }))
						dispatch(setLastSavedContent({ docID: props.docID, lastSavedContent: doc.content }))
						dispatch(setReferences({ docID: props.docID, references: doc.references }))
						setDocLoading(false)
					} else {
						setInvalid(true)
					}
				})
				.catch((e) => {
					Sentry.captureException(e)
				})
		}
	}, [currentUser, dispatch, props.docID, fetched, userIDCookie, user.id])

	useEffect(() => {
		if (props.docID === '') {
			setInvalid(true)
			return
		}
		getDocument()
	}, [props.docID, getDocument, fetched, user])

	useEffect(() => {
		dispatch(setLastAggregatePageVisited({ value: '/create' }))
		//Update only on initial page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const loader = (
		<div id={pageName} className="flex h-screen w-full justify-center items-center">
			<Loader />
		</div>
	)

	const accountModals = useMemo(
		() => (
			<>
				<UpgradeModals
					subscriptionModalOpen={accountModalState.subscriptionModalOpen}
					loginModalOpen={accountModalState.loginModalOpen}
					setSubscriptionModalOpen={(open: boolean) => {
						setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: open }))
					}}
					setLoginModalOpen={(open: boolean) => {
						setAccountModalState((prev) => ({ ...prev, loginModalOpen: open }))
					}}
					premiumPath={true}
				/>
			</>
		),
		[accountModalState]
	)

	if (invalid) {
		return (
			<div id={pageName}>
				<h5 className="notFound header-md">Document not found</h5>
			</div>
		)
	}

	if (loading)
		return (
			<>
				{accountModals}
				{loader}
			</>
		)

	return (
		<>
			{accountModals}
			<Essay {...props} />
		</>
	)
}

export default Create
