import { useDispatch } from 'react-redux'
import { setIsOnSubtopicsStep } from '../../../redux/docSlice'
import { useDocId } from '../../../hooks/docID'
import './_Subtopics.scss'
import Button from '../../../Components/Buttons'
import { useMemo } from 'react'
import { ChevronLeft } from '@mui/icons-material'
import { useProcessedSubtopics } from '../hooks'
import useNumSubtopics from '../../../hooks/numSubtopics'
import { SubtopicsInput } from './SubtopicsInput'

export const SubTopics = ({ generateEssay }: { generateEssay: () => void }) => {
	const { minNumberOfSubtopics } = useNumSubtopics()
	const dispatch = useDispatch()
	const docID = useDocId()
	const processedSubtopics = useProcessedSubtopics()

	const invalidSubtopics = useMemo(() => {
		return processedSubtopics.filter((subtopic) => subtopic.length > 0).length - 2 < minNumberOfSubtopics
	}, [processedSubtopics, minNumberOfSubtopics])

	return (
		<div className="subtopics relative">
			<Button
				type="tertiary"
				className="absolute left-0 top-0 sm:block hidden"
				onClick={() =>
					dispatch(
						setIsOnSubtopicsStep({
							docID: docID,
							isOnSubtopicsStep: false,
						})
					)
				}
			>
				<div className="flex items-center gap-2">
					<div className="inline-block text-md w-2 relative right-2">
						<ChevronLeft />
					</div>
					<div>Back</div>
				</div>
			</Button>
			<h6 className="header-sm">Enter subtopics:</h6>
			<p>We've written these to help get you started.</p>
			<SubtopicsInput docID={docID} />
			<Button
				type="tertiary"
				onClick={generateEssay}
				className="w-full h-12 text-2xl mt-6 fancy-font"
				emphasize
				disabled={invalidSubtopics}
			>
				Generate Essay
			</Button>
		</div>
	)
}
