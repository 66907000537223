import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../contexts/Auth'
import { useCookieUser } from './cookieUser'
import { DocumentState, ReferencesInfo, UserWriterSelections } from '../redux/types'
import {
	selectDocumentState,
	setContent,
	setIsGenerating,
	setIsGeneratingSubtopics,
	setIsHumanizing,
	setIsOnSubtopicsStep,
	setIsTypingEssay,
	setNeedsHumanizing,
	setTitle,
	setUserWriterSelections,
	setSetup,
	setSetupChanged,
	setReferences,
	createNewDraft,
	updateDraft,
	setHumanized,
	setAllReferences,
	setGeneratingReferences,
} from '../redux/docSlice'
import { CONSTANTS, DOC_TEMPLATES, HUMANIZER_OPTIONS } from '../constants'
import { useEffect } from 'react'
import { selectUser } from '../redux/systemSlice'
import * as Sentry from '@sentry/react'
import { FEATURE_FLAGS, useFreeWordLimit } from './featureFlags'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { htmlToStringBesidesH6 } from '../helpers/utility'
import useIsPremium from './useIsPremium'
import useNumSubtopics from './numSubtopics'
import { splitHTMLInto500WordChunks } from './helpers'
import { atom, useAtom } from 'jotai'

export const needsEssayGenerationAtom = atom(false)

const useWriteEssay = ({
	docID,
	type,
	topicValue,
	setTopicValue,
	setLocalUserWriterSelections,
	setErrorModalOpen,
	allowEssayGeneration = false,
}: {
	type?: keyof typeof DOC_TEMPLATES | undefined
	docID: string
	topicValue?: string
	setTopicValue?: (value: string) => void
	setLocalUserWriterSelections?: (value: UserWriterSelections) => void
	setErrorModalOpen?: (value: boolean) => void
	allowEssayGeneration?: boolean
}) => {
	const freeWordLimit = useFreeWordLimit()
	const { userIDCookie } = useCookieUser()
	const { currentUser } = useAuth() as any
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const user = useSelector(selectUser)
	const humanizerV4 = useFeatureFlagVariantKey(FEATURE_FLAGS.HUMANIZER_V4)
	const concurrentSectionGenerationFF = useFeatureFlagVariantKey(FEATURE_FLAGS.CONCURRENT_SECTION_GENERATION)
	const posthog = usePostHog()
	const isPremium = useIsPremium()
	const { averageNumberOfSubtopics } = useNumSubtopics()
	const selectReferencesFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SELECT_REFERENCES)
	const [needsEssayGeneration, setNeedsEssayGeneration] = useAtom(needsEssayGenerationAtom)

	const topicValueLocal = topicValue ?? documentState?.title

	useEffect(() => {
		if (selectReferencesFF === 'test' && allowEssayGeneration && needsEssayGeneration && !documentState.isGenerating) {
			setLocalUserWriterSelections?.(documentState.userWriterSelections)
			dispatch(setIsGenerating({ docID: docID, isGenerating: true }))
			dispatch(setSetup({ docID: docID, setup: true }))
			dispatch(setSetupChanged({ docID: docID, setupChanged: true }))
			dispatch(setTitle({ docID: docID, title: topicValueLocal }))
		}

		if (
			selectReferencesFF === 'test' &&
			allowEssayGeneration &&
			needsEssayGeneration &&
			(!documentState.generatingReferences || !documentState.userWriterSelections.refStyle)
		) {
			setNeedsEssayGeneration(false)
			generateContent()
		}
	}, [
		needsEssayGeneration,
		selectReferencesFF,
		topicValueLocal,
		documentState.generatingReferences,
		documentState.userWriterSelections.refStyle,
		allowEssayGeneration,
	])

	useEffect(() => {
		if (documentState.drafts.length === 2) {
			dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
		}
	}, [documentState.drafts])

	useEffect(() => {
		setTopicValue?.(documentState?.title ?? '')
	}, [documentState?.title])

	const shortenEssayMaxFreeWords = (essay: string) => {
		const essayArray = essay.split('.')
		let newEssay = ''
		let wordCount = 0
		while (wordCount < freeWordLimit && essayArray.length !== 0) {
			wordCount += essayArray[0].split(' ').length
			if (essayArray.length !== 1) {
				newEssay += essayArray.shift() + '.'
			}
		}
		return newEssay
	}

	async function updateHumanized(humanized: boolean) {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				id: docID,
				humanized: humanized,
			}),
		}
		fetch(process.env.REACT_APP_API_ROOT + '/document/update/', requestOptions)
	}

	async function makeHumanizerRequest(essayContent: string, humanizerIntensity?: string) {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				text: htmlToStringBesidesH6(essayContent),
				humanizerIntensity: humanizerIntensity ?? documentState.userWriterSelections?.humanizerIntensity,
				token: currentUser ? await currentUser.getIdToken() : undefined,
				userID: user.id ?? userIDCookie,
				useHumanizerV2: humanizerV4 === 'test' ? true : false,
			}),
		}

		try {
			// Await the fetch call and its conversion to JSON
			const response = await fetch(process.env.REACT_APP_API_ROOT + '/essay/humanize/', requestOptions)
			const result = await response.json()
			return result
		} catch (e) {
			Sentry.captureException(e)
			setErrorModalOpen?.(true)
			// Ensure that in case of error, the function returns a fallback or error indicator
			return '' // Consider returning null or a specific error object depending on your handling logic
		}
	}

	async function humanizeEssay(essayContent: string, draftIdx: number, humanizerIntensity?: string) {
		dispatch(setIsHumanizing({ docID: docID, isHumanizing: true }))

		let humanizedContent = ''

		if (humanizerV4 === 'test') {
			const chunks: string[] = splitHTMLInto500WordChunks(essayContent, 500)

			// Kick off all requests in parallel
			const promises = chunks.map((chunk) => makeHumanizerRequest(chunk, humanizerIntensity))

			// Await all results at once
			const results = await Promise.all(promises)

			// Combine the results
			for (const result of results) {
				if (result.content) {
					humanizedContent += result.content
				}
			}
		} else {
			let chunks = []

			if (essayContent.length > 1250 * 5) {
				// Split the essay into two chunks based on newlines
				const splitContent = essayContent.split('\n')
				const mid = Math.floor(splitContent.length / 2)
				const firstHalf = splitContent.slice(0, mid).join('\n')
				const secondHalf = splitContent.slice(mid).join('\n')
				chunks = [firstHalf, secondHalf]
			} else {
				chunks = [essayContent]
			}

			for (let i = 0; i < chunks.length; i++) {
				const result = await makeHumanizerRequest(chunks[i], humanizerIntensity)
				if (result.content) {
					humanizedContent += result.content // Adjust this line based on the actual property
				}
			}
		}

		if (draftIdx !== -1) {
			dispatch(
				updateDraft({
					docID: docID,
					content: humanizedContent,
					draftIdx: draftIdx,
				})
			)
			if (draftIdx === 0) {
				dispatch(setContent({ docID: docID, content: humanizedContent }))
			}
		} else {
			dispatch(setContent({ docID: docID, content: humanizedContent }))
		}
		dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
		dispatch(setIsHumanizing({ docID: docID, isHumanizing: false }))
		dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
		dispatch(setNeedsHumanizing({ docID: docID, needsHumanizing: false }))
		dispatch(setHumanized({ docID: docID, humanized: true }))
		updateHumanized(true)
	}

	async function writeOutline() {
		const requestBody = JSON.stringify({
			prompt: topicValueLocal,
			userId: user.id ?? userIDCookie,
			docId: docID,
			userWriterSelections: documentState.userWriterSelections,
		})

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: requestBody,
		}

		const url = `${process['env']['REACT_APP_API_ROOT']}/essay/write-outline/`
		fetch(url, requestOptions)
			.then((res) => {
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: true }))
				dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
				return res.json()
			})
			.then(async (result: { essay: string }) => {
				dispatch(setContent({ docID: docID, content: result.essay.trim() }))
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
			})
			.catch((e) => {
				Sentry.captureException(e)
				setErrorModalOpen?.(true)
				dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
				dispatch(setContent({ docID: docID, content: '' }))
			})
	}

	async function getSectionContent(
		topic: string,
		sectionType: 'introduction' | 'body' | 'conclusion',
		essayContent: string
	) {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				prompt: topicValueLocal,
				userId: user.id ?? userIDCookie,
				docId: docID,
				userWriterSelections: documentState.userWriterSelections,
				sectionType: sectionType,
				topic: topic,
				essay: essayContent,
				useNeuralSearch: false,
			}),
		}

		const url = `${process['env']['REACT_APP_API_ROOT']}/essay/write-section/`

		try {
			const response = await fetch(url, requestOptions)
			const result = await response.json()
			if (!!result.references && result.references.length > 0) {
				posthog.capture('references-generated', { neural: false })
				dispatch(
					setReferences({
						docID: docID,
						references: result.references,
					})
				)
			}
			return result.essay.trim()
		} catch (e) {
			Sentry.captureException(e)
			throw new Error('Failed to fetch section content')
		}
	}

	async function writeLongEssay(draftIdx: number) {
		let content = ''
		let { subTopics } = documentState.userWriterSelections
		let shorteningEssay = false

		if (!isPremium) {
			shorteningEssay = true
			subTopics = freeWordLimit > 250 ? [subTopics[0], subTopics[1]] : [subTopics[0]]
		}

		try {
			if (concurrentSectionGenerationFF === 'test') {
				// Generate all sections concurrently
				const sectionPromises = subTopics.map((topic, i) => {
					const sectionType =
						i === 0 ? 'introduction' : shorteningEssay ? 'body' : i === subTopics.length - 1 ? 'conclusion' : 'body'
					return getSectionContent(topic, sectionType, content)
				})
				const sections = await Promise.all(sectionPromises)
				content = sections.map((section) => section.trim()).join('')
			} else {
				// Generate sections sequentially
				for (let i = 0; i < subTopics.length; i++) {
					const sectionType =
						i === 0 ? 'introduction' : shorteningEssay ? 'body' : i === subTopics.length - 1 ? 'conclusion' : 'body'
					const sectionContent = await getSectionContent(subTopics[i], sectionType, content)
					content += sectionContent.trim()
				}
			}

			if (shorteningEssay) {
				content = shortenEssayMaxFreeWords(content)
			}

			// Dispatch the content and draft updates
			if (draftIdx === 0) {
				dispatch(setContent({ docID: docID, content }))
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
			}
			dispatch(
				createNewDraft({
					docID: docID,
					draft: { content, selected: draftIdx === 0 },
					idx: draftIdx,
				})
			)

			// Humanize if applicable
			if (documentState.userWriterSelections?.humanizerIntensity !== HUMANIZER_OPTIONS[0]) {
				await humanizeEssay(content.trim(), draftIdx)
			}
		} catch (e) {
			Sentry.captureException(e)
			setErrorModalOpen?.(true)
			dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
			dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
			dispatch(setContent({ docID: docID, content: '' }))
		}
	}

	async function writeShortEssay(draftIdx: number) {
		const requestBody = JSON.stringify({
			prompt: topicValueLocal,
			userId: user.id ?? userIDCookie,
			docId: docID,
			userWriterSelections: documentState.userWriterSelections,
			useNeuralSearch: false,
		})

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: requestBody,
		}

		const url = `${process['env']['REACT_APP_API_ROOT']}/essay/write-short-essay/`
		fetch(url, requestOptions)
			.then((res) => {
				return res.json()
			})
			.then(async (result: { essay: string; references: ReferencesInfo[] }) => {
				let essay = result.essay.trim()
				if (!isPremium && documentState.userWriterSelections.wordCount > freeWordLimit) {
					essay = shortenEssayMaxFreeWords(essay)
				}

				if (draftIdx === 0) {
					dispatch(setContent({ docID: docID, content: essay }))
					dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
				}
				dispatch(
					createNewDraft({
						docID: docID,
						draft: { content: essay, selected: draftIdx === 0 },
						idx: draftIdx,
					})
				)

				if (!!result.references && result.references.length > 0) {
					posthog.capture('references-generated', { neural: false })
					dispatch(
						setReferences({
							docID: docID,
							references: result.references,
						})
					)
				}
				if (documentState.userWriterSelections?.humanizerIntensity !== HUMANIZER_OPTIONS[0]) {
					await humanizeEssay(essay, draftIdx)
				}
			})
			.catch((e) => {
				Sentry.captureException(e)
				setErrorModalOpen?.(true)
				dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
				dispatch(setContent({ docID: docID, content: '' }))
			})
	}

	async function generateReferences() {
		dispatch(setGeneratingReferences({ docID: docID, generatingReferences: true }))
		const requestBody = JSON.stringify({
			prompt: topicValueLocal,
			userId: user.id ?? userIDCookie,
			docId: docID,
			wordCount: documentState.userWriterSelections.wordCount,
			autoExa: false,
		})

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: requestBody,
		}

		const url = `${process['env']['REACT_APP_API_ROOT']}/essay/generate-references/`

		try {
			const res = await fetch(url, requestOptions)
			const result = await res.json()
			dispatch(setGeneratingReferences({ docID: docID, generatingReferences: false }))

			if (result.references && result.references.length > 0) {
				posthog.capture('references-generated', { neural: false })
				dispatch(
					setReferences({
						docID: docID,
						references: result.references,
					})
				)
				dispatch(
					setAllReferences({
						docID: docID,
						allReferences: result.allReferences,
					})
				)
			}
		} catch (e) {
			Sentry.captureException(e)
			setErrorModalOpen?.(true)
			dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
			dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
			dispatch(setContent({ docID: docID, content: '' }))
		}
	}

	async function generateContent() {
		if (selectReferencesFF !== 'test') {
			setLocalUserWriterSelections?.(documentState.userWriterSelections)
			dispatch(setIsGenerating({ docID: docID, isGenerating: true }))
			dispatch(setSetup({ docID: docID, setup: true }))
			dispatch(setSetupChanged({ docID: docID, setupChanged: true }))
			dispatch(setTitle({ docID: docID, title: topicValueLocal }))
		}

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				topic: topicValueLocal,
				id: docID,
			}),
		}

		fetch(process['env']['REACT_APP_API_ROOT'] + '/document/update/', requestOptions)
			.then((res) => res.json())
			.then(async (success: boolean) => {
				if (success) {
					if (type === DOC_TEMPLATES.Outline) {
						writeOutline()
					} else if (documentState.userWriterSelections?.wordCount >= CONSTANTS.TOPIC_GENERATION_BREAKPOINT) {
						if (selectReferencesFF !== 'test' && documentState.userWriterSelections.refStyle) {
							await generateReferences()
						}
						writeLongEssay(0)
						writeLongEssay(1)
					} else {
						if (selectReferencesFF !== 'test' && documentState.userWriterSelections.refStyle) {
							await generateReferences()
						}
						writeShortEssay(0)
						writeShortEssay(1)
					}
					if (type !== DOC_TEMPLATES.Outline) {
						dispatch(
							setHumanized({
								docID: docID,
								humanized: documentState.userWriterSelections.humanizerIntensity !== 'NONE',
							})
						)
						updateHumanized(documentState.userWriterSelections.humanizerIntensity !== 'NONE')
					}
				} else {
					dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
				}
			})
			.catch((e) => {
				Sentry.captureException(e)
				dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
			})
	}

	const getSubtopics = async () => {
		dispatch(setIsGeneratingSubtopics({ docID: docID, isGeneratingSubtopics: true }))
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				wordCount: documentState.userWriterSelections.wordCount,
				prompt: topicValueLocal,
				type: documentState.userWriterSelections.type,
				thesisStatement: documentState.userWriterSelections.thesisStatement.active
					? documentState.userWriterSelections.thesisStatement.text
					: '',
				userId: user.id ?? userIDCookie,
				numSubtopics: averageNumberOfSubtopics,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/subtopics/', requestOptions)
			.then((res) => res.json())
			.then((response: { outline: string[] }) => {
				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: {
							subTopics: response.outline,
						},
					})
				)
				dispatch(
					setIsOnSubtopicsStep({
						docID: docID,
						isOnSubtopicsStep: true,
					})
				)
				dispatch(setIsGeneratingSubtopics({ docID: docID, isGeneratingSubtopics: false }))
			})
			.catch((e) => {
				Sentry.captureException(e)
				dispatch(setIsGeneratingSubtopics({ docID: docID, isGeneratingSubtopics: false }))
			})
	}

	return {
		generateEssay: () => {
			if (selectReferencesFF === 'test') {
				setNeedsEssayGeneration(true)
			} else {
				generateContent()
			}
		},
		getSubtopics,
		humanizeEssay,
		generateReferences,
	}
}

export default useWriteEssay
