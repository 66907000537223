import { HumanizerEditor } from './Humanizer/humanizer-editor'

const HumanizerPage = () => {
	return (
		<div className="flex flex-col gap-5 mt-20">
			<div className="flex flex-col">
				<div className="text-3xl font-semibold text-center">AI Humanizer</div>
				<div className="text-center text-gray-700">Rewrite your AI text to bypass AI detectors.</div>
			</div>
			<div className="z-10 max-w-6xl w-full mx-auto pb-96 md:pb-24">
				<HumanizerEditor />
			</div>
			<div className="w-screen h-screen fixed bg-gradient-to-b from-white to-[#513bf6] top-0 opacity-15" />
		</div>
	)
}

export default HumanizerPage
