import './_index.scss'

import { useEffect, useMemo, useState } from 'react'
import Button from '../../../Components/Buttons'
import { TextareaAutosize } from '@mui/material'
import { DocumentState, REF_STYLES } from '../../../redux/types'
import { useDispatch, useSelector } from 'react-redux'
import { selectDocumentState, setTitle, setUserWriterSelections } from '../../../redux/docSlice'
import { useDocId } from '../../../hooks/docID'
import { CONSTANTS, DOC_TEMPLATES, HUMANIZER_OPTIONS, TYPE_OPTIONS } from '../../../constants'
import TitleQuality from '../TitleQuality'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6'
import { IoIosRocket } from 'react-icons/io'
import { FEATURE_FLAGS, useFreeWordLimit } from '../../../hooks/featureFlags'
import WritingStyleSelection from '../../../Components/WriterOptions/WritingStyle'
import useIsPremium from '../../../hooks/useIsPremium'
import useWriteEssay from '../../../hooks/useWriteEssay'
import { useProcessedSubtopics } from '../hooks'
import { useLocalStorage } from '../../../helpers/utility'
import { blankUserWriterSelections } from '../../../redux/constants'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { SubtopicsInput } from '../Subtopics/SubtopicsInput'
import useNumSubtopics from '../../../hooks/numSubtopics'
import ReferencesSelection from '../References/ReferencesSelection'

const TopicStep = () => {
	const [topicInputFocused, setTopicInputFocused] = useState(false)
	const docId = useDocId()
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const [displayedPlaceholder, setDisplayedPlaceholder] = useState('')

	const placeholderText = 'Type your topic here...'

	useEffect(() => {
		let index = 0
		const interval = setInterval(() => {
			if (index < placeholderText.length) {
				setDisplayedPlaceholder((prev) => prev + placeholderText[index])
				index++
			} else {
				clearInterval(interval)
			}
		}, 30)

		return () => clearInterval(interval) // Cleanup on unmount
	}, [])

	useEffect(() => {
		setDisplayedPlaceholder('')
	}, [])

	if (!documentState) return null

	return (
		<div className="flex flex-col gap-5">
			<div className="flex flex-col gap-2">
				<div className="text-3xl md:text-4xl text-center font-semibold mt-12 md:mt-18">
					What would you like to write about?
				</div>
				<div className="text-lg gray-700 text-center">Enter your topic and let The Good AI craft your content</div>
			</div>
			<div className="flex flex-col gap-3">
				<TextareaAutosize
					placeholder={displayedPlaceholder}
					className={`w-full resize-none outline-none border border-gray-400 px-2 py-4 md:px-4 md:py-6 rounded-xl ${
						documentState.title?.length > 100 ? 'md:text-3xl text-xl' : 'md:text-3xl text-2xl'
					} font-semibold`}
					autoFocus
					onFocus={() => {
						setTopicInputFocused(true)
					}}
					onBlur={() => {
						setTopicInputFocused(false)
					}}
					onChange={(e) => {
						dispatch(setTitle({ docID: docId, title: e.target.value }))
					}}
					minRows={1}
					id="topicHeader"
					maxRows={topicInputFocused ? undefined : 3}
					maxLength={CONSTANTS.MAX_TOPIC_LENGTH}
					value={documentState.title}
				/>
				<TitleQuality topic={documentState.title} />
			</div>
			<div className="flex flex-col gap-2 mt-5 items-start">
				<div className="text-xl font-semibold mb-1">Suggested Topics:</div>
				{[
					'Why the Battle of Midway was a turning point in WW2',
					'The Influence of Arts Education on Culture, Society, and Population Happiness',
					'The experience that taught me how looks could be deceiving',
				].map((topic, i) => (
					<div
						key={i}
						className="rounded px-3 py-1 font-semibold cursor-pointer hover:bg-gray-100 border border-gray-400"
						onClick={() => dispatch(setTitle({ docID: docId, title: topic }))}
					>
						{topic}
					</div>
				))}
			</div>
		</div>
	)
}

const WordCountStep = () => {
	const docId = useDocId()
	const dispatch = useDispatch()
	const freeWordLimit = useFreeWordLimit()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))

	const pageCount = useMemo(() => {
		const wordCount = documentState.userWriterSelections?.wordCount ?? freeWordLimit
		return (wordCount / 500).toFixed(1)
	}, [documentState.userWriterSelections?.wordCount])

	if (!documentState) return null

	return (
		<div className="flex flex-col gap-5">
			<div className="flex flex-col gap-2">
				<div className="text-3xl md:text-4xl text-center font-semibold mt-12 md:mt-18">
					How long would you like it to be?
				</div>
				<div className="text-lg gray-700 text-center">Select your desired word count</div>
			</div>
			<div className="flex flex-col gap-8">
				<div className={`sliderValue mb-3 text-4xl mt-10 text-center`}>
					<input
						onChange={(e) => {
							dispatch(
								setUserWriterSelections({
									docID: docId,
									userWriterSelections: { wordCount: parseInt(e.target.value) },
								})
							)
						}}
						onBlur={() => {
							const input = documentState.userWriterSelections?.wordCount ?? freeWordLimit
							if (input > CONSTANTS.WORD_COUNT_MAX) {
								dispatch(
									setUserWriterSelections({
										docID: docId,
										userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MAX },
									})
								)
							} else if (input < CONSTANTS.WORD_COUNT_MIN) {
								dispatch(
									setUserWriterSelections({
										docID: docId,
										userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MIN },
									})
								)
							}
						}}
						type="number"
						className={`value !w-[110px] text-center rounded px-2 py-1 border mr-2 font-semibold border-gray-500`}
						value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
						placeholder=""
					/>
					<span className="text-3xl relative bottom-0.5 text-gray-700 font-semibold">
						words
						<span className="md:inline hidden">, or ~{pageCount} pages</span>
					</span>
				</div>
				<div>
					<input
						type="range"
						min={CONSTANTS.WORD_COUNT_MIN}
						max={CONSTANTS.WORD_COUNT_MAX}
						step={50}
						className="slider"
						value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
						onChange={(e) => {
							dispatch(
								setUserWriterSelections({
									docID: docId,
									userWriterSelections: { wordCount: parseInt(e.target.value) },
								})
							)
						}}
					/>
					<div className="h-2 bg-gray-100 w-full relative -top-[15px] rounded-full z-[-1]">
						<div
							className="h-full bg-gray-900 rounded-full"
							style={{
								width: `${
									((documentState.userWriterSelections?.wordCount ?? freeWordLimit) / CONSTANTS.WORD_COUNT_MAX) * 100
								}%`,
							}}
						></div>
					</div>
				</div>
			</div>
		</div>
	)
}

const EssayTypeStep = () => {
	const docId = useDocId()
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))

	if (!documentState) return null

	return (
		<div className="flex flex-col gap-12">
			<div className="flex flex-col gap-2">
				<div className="text-3xl md:text-4xl text-center font-semibold mt-12 md:mt-18">
					What type of essay would you like?
				</div>
				<div className="text-lg gray-700 text-center">Select the type of essay you want to create</div>
			</div>
			<div className={`grid md:grid-cols-3 grid-cols-1 gap-3`}>
				{TYPE_OPTIONS.map((str, index) => {
					return (
						<Button
							className={`w-full text-lg rounded py-1 font-medium ${
								str === documentState.userWriterSelections?.type
									? ''
									: 'hover:bg-gray-100 border-dotted border border-gray-500'
							}`}
							key={str + index}
							type="primary"
							emphasize={str === documentState.userWriterSelections?.type}
							onClick={(e) => {
								dispatch(
									setUserWriterSelections({
										docID: docId,
										userWriterSelections: { type: str },
									})
								)
							}}
						>
							<span>
								{str} {index === 0 && '(default)'}
							</span>
						</Button>
					)
				})}
			</div>
		</div>
	)
}

const WritingStyleStep = () => {
	const docId = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))

	if (!documentState) return null

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-2">
				<div className="text-3xl md:text-4xl text-center font-semibold mt-12 md:mt-18">
					What writing style would you like?
				</div>
				<div className="text-lg gray-700 text-center">Use AI to clone your writing style</div>
			</div>
			<WritingStyleSelection docID={docId} omitHeader />
		</div>
	)
}

const ReferencesStep = () => {
	const docId = useDocId()
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const selectReferencesFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SELECT_REFERENCES)

	let options = [null, ...Object.keys(REF_STYLES)]

	if (!documentState) return null

	return (
		<div className="flex flex-col gap-12">
			<div className="flex flex-col gap-2">
				<div className="text-3xl md:text-4xl text-center font-semibold mt-12 md:mt-18">Need References?</div>
				<div className="text-lg gray-700 text-center">
					{selectReferencesFF === 'test'
						? 'Select your reference style and choose your citations'
						: 'Select your reference style to generate real citations'}
				</div>
			</div>
			<div
				className={`grid gap-3 ${
					selectReferencesFF === 'test' ? 'md:grid-cols-6 grid-cols-2' : 'md:grid-cols-3 grid-cols-1'
				}`}
			>
				{options.map((str, index) => {
					return (
						<Button
							className={`w-full text-lg rounded py-1 font-medium ${
								str === documentState.userWriterSelections?.refStyle
									? ''
									: 'hover:bg-gray-100 border-dotted border border-gray-500'
							} ${!str ? 'text-gray-700' : ''}`}
							key={(str ?? '') + index}
							type="primary"
							emphasize={str === documentState.userWriterSelections?.refStyle}
							onClick={(e) => {
								dispatch(
									setUserWriterSelections({
										docID: docId,
										userWriterSelections: { refStyle: str },
									})
								)
							}}
						>
							<span>
								{str
									? {
											None: 'None',
											APA_7: selectReferencesFF === 'test' ? 'APA 7' : 'APA 7 (default)',
											MLA_9: 'MLA 9',
											HARVARD: 'Harvard',
											IEEE: 'IEEE',
											CHICAGO: 'Chicago',
									  }[str]
									: 'None'}
							</span>
						</Button>
					)
				})}
			</div>
			{selectReferencesFF === 'test' && <ReferencesSelection />}
		</div>
	)
}

const HumanizeStep = () => {
	const docId = useDocId()
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))

	if (!documentState) return null

	return (
		<div className="flex flex-col gap-12">
			<div className="flex flex-col gap-2">
				<div className="text-3xl md:text-4xl text-center font-semibold mt-12 md:mt-18">
					What level of humanization would you like?
				</div>
				<div className="text-lg gray-700 text-center">
					The higher the intensity, the harder your essay will be to read, but it will also be less likely to be
					detected as AI-generated.
				</div>
			</div>
			<div className={`grid md:grid-cols-4 grid-cols-1 gap-3`}>
				{HUMANIZER_OPTIONS.map((str, index) => {
					return (
						<Button
							className={`w-full text-lg rounded py-1 font-medium ${
								str === documentState.userWriterSelections?.humanizerIntensity
									? ''
									: 'hover:bg-gray-100 border-dotted border border-gray-500'
							}`}
							key={str + index}
							type="primary"
							emphasize={str === documentState.userWriterSelections?.humanizerIntensity}
							onClick={(e) => {
								dispatch(
									setUserWriterSelections({
										docID: docId,
										userWriterSelections: { humanizerIntensity: str },
									})
								)
							}}
						>
							<span className="capitalize">{str.toLowerCase()}</span>
						</Button>
					)
				})}
			</div>
		</div>
	)
}

const SubtopicsStep = () => {
	const docId = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))

	if (!documentState) return null

	return (
		<div className="flex flex-col gap-8">
			<div className="flex flex-col gap-2">
				<div className="text-3xl md:text-4xl text-center font-semibold mt-12 md:mt-18">What are your subtopics?</div>
				<div className="text-lg gray-700 text-center">
					We've generated some subtopics for you based on your word count.
				</div>
			</div>
			<div>
				<SubtopicsInput docID={docId} />
			</div>
		</div>
	)
}

const SteppedEssaySetup = ({
	type,
	setErrorModalOpen,
}: {
	type: keyof typeof DOC_TEMPLATES | undefined
	setErrorModalOpen: (open: boolean) => void
}) => {
	const docID = useDocId()
	const [step, setStep] = useState(0)
	const [includeSubtopicsStep, setIncludeSubtopicsStep] = useState(false)
	const docId = useDocId()
	const isPremium = useIsPremium()
	const processedSubtopics = useProcessedSubtopics()
	const posthog = usePostHog()
	const { minNumberOfSubtopics } = useNumSubtopics()
	const { generateReferences } = useWriteEssay({ docID })
	const selectReferencesFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SELECT_REFERENCES)

	const [, setLocalUserWriterSelections] = useLocalStorage(docId, blankUserWriterSelections)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const { generateEssay, getSubtopics } = useWriteEssay({
		docID: docId,
		type: type,
		setLocalUserWriterSelections: (selections) => {
			setLocalUserWriterSelections(selections)
		},
		setErrorModalOpen: setErrorModalOpen,
	})
	const steps = useMemo(() => {
		let baseSteps = [
			{ id: 'topic', step: <TopicStep /> },
			{ id: 'wordCount', step: <WordCountStep /> },
			{ id: 'essayType', step: <EssayTypeStep /> },
			{ id: 'writingStyle', step: <WritingStyleStep /> },
			{ id: 'references', step: <ReferencesStep /> },
		]
		if (isPremium) {
			baseSteps.push({ id: 'humanize', step: <HumanizeStep /> })
		}
		if (includeSubtopicsStep) {
			baseSteps.push({ id: 'subtopics', step: <SubtopicsStep /> })
		}
		return baseSteps
	}, [isPremium, includeSubtopicsStep])

	const subtopicsStepDisabled = useMemo(() => {
		return processedSubtopics.filter((subtopic) => subtopic.length > 0).length - 2 < minNumberOfSubtopics
	}, [processedSubtopics])

	// const disableNext = [!documentState?.title || documentState?.title?.length === 0, false, false, false, false]
	const disableNext = {
		topic: !documentState?.title || documentState?.title?.length === 0,
		wordCount: false,
		essayType: false,
		writingStyle: false,
		references: false,
		humanize: false,
		subtopics: subtopicsStepDisabled,
	}

	const ButtonWithArrow = ({ direction, children, ...props }: any) => {
		return (
			<Button
				{...props}
				className={`flex items-center group transition-all text-lg gap-5 ${
					direction === 'left' ? 'pr-7 pl-6' : 'pl-7 pr-6'
				}`}
			>
				{direction !== 'left' && children}
				<span
					className={`text-xl h-4 w-4 relative bottom-0.5 transition-all ${
						direction === 'left' ? 'group-hover:-left-1 left-0' : 'group-hover:right-0 right-1'
					}`}
				>
					{direction === 'left' ? <FaArrowLeft /> : <FaArrowRight />}
				</span>
				{direction === 'left' && children}
			</Button>
		)
	}

	const NavigationButtons = () => {
		return (
			<div className="flex justify-center gap-4 mx-auto w-48 mt-12">
				{step !== 0 && (
					<div className="flex justify-end">
						<ButtonWithArrow type="ghost" onClick={() => setStep(Math.max(0, step - 1))} direction="left">
							Back
						</ButtonWithArrow>
					</div>
				)}
				{step === steps.length - 1 ? (
					<div>
						{
							<Button
								type="default"
								className="pl-8 pr-8 text-lg group"
								// @ts-ignore
								disabled={disableNext[steps[step].id]}
								onClick={() => {
									generateEssay()
									posthog.capture('generate-essay', {
										word_count: documentState.userWriterSelections.wordCount,
										humanizer_intensity: documentState.userWriterSelections.humanizerIntensity,
										ref_style: documentState.userWriterSelections.refStyle,
										essay_type: documentState.userWriterSelections.type,
									})
								}}
							>
								<div className="flex items-center gap-2">
									Generate
									<IoIosRocket />
								</div>
							</Button>
						}
					</div>
				) : (
					<div>
						<ButtonWithArrow
							type="default"
							// @ts-ignore
							disabled={disableNext[steps[step].id]}
							onClick={() => {
								posthog.capture('step-completed', { step: steps[step].id })
								setStep(Math.min(step + 1, steps.length - 1))
								if (steps[step].id === 'wordCount') {
									const generateSubtopics =
										documentState.userWriterSelections.wordCount >= CONSTANTS.TOPIC_GENERATION_BREAKPOINT
									setIncludeSubtopicsStep(generateSubtopics)
									if (generateSubtopics) {
										getSubtopics()
									}
									if (selectReferencesFF === 'test') {
										generateReferences()
									}
								}
							}}
						>
							Next
						</ButtonWithArrow>
					</div>
				)}
			</div>
		)
	}

	return (
		<>
			<div
				className={`grid gap-2 relative md:-top-8 z-20`}
				style={{ gridTemplateColumns: `repeat(${steps.length}, 1fr)` }}
			>
				{steps.map((_, i) => (
					<div className="bg-gray-300 h-1">
						<div key={i} className={`h-1 rounded bg-gray-900 transition-all ${i <= step ? 'w-full' : 'w-0'}`}></div>
					</div>
				))}
			</div>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-4">{steps[step].step}</div>
				<NavigationButtons />
			</div>
		</>
	)
}

export default SteppedEssaySetup
