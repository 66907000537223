import { Node } from '@tiptap/pm/model'
import { NodeSelection } from '@tiptap/pm/state'
import { Editor } from '@tiptap/react'
import { TextSelection } from 'prosemirror-state'
import { useCallback } from 'react'

const useContentItemActions = (editor: Editor | null, currentNode: Node | null, currentNodePos: number) => {
	const resetTextFormatting = useCallback(() => {
		if (!editor) return
		const chain = editor.chain()

		chain.setNodeSelection(currentNodePos).unsetAllMarks()

		if (currentNode?.type.name !== 'paragraph') {
			chain.setParagraph()
		}

		chain.run()
	}, [editor, currentNodePos, currentNode?.type.name])

	const duplicateNode = useCallback(() => {
		if (!editor) return

		editor.commands.setNodeSelection(currentNodePos)

		const { $anchor } = editor.state.selection
		const selectedNode = $anchor.node(1) || (editor.state.selection as NodeSelection).node

		editor
			.chain()
			.setMeta('hideDragHandle', true)
			.insertContentAt(currentNodePos + (currentNode?.nodeSize || 0), selectedNode.toJSON())
			.run()
	}, [editor, currentNodePos, currentNode?.nodeSize])

	const copyNodeToClipboard = useCallback(() => {
		if (!editor) return

		editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).run()

		document.execCommand('copy')
	}, [editor, currentNodePos])

	const deleteNode = useCallback(() => {
		if (!editor) return

		editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).deleteSelection().run()
	}, [editor, currentNodePos])

	const handleAdd = useCallback(() => {
		if (!editor) return

		if (currentNodePos !== -1) {
			const currentNodeSize = currentNode?.nodeSize || 0
			const insertPos = currentNodePos + currentNodeSize
			const currentNodeIsEmptyParagraph = currentNode?.type.name === 'paragraph' && currentNode?.content?.size === 0

			editor
				.chain()
				.command(({ dispatch, tr, state }) => {
					if (!dispatch) return true

					// Check the node immediately after our current node
					const nextNode = tr.doc.nodeAt(insertPos)

					// If the next node is a paragraph and has exactly one slash
					if (nextNode && nextNode.type.name === 'paragraph' && nextNode.textContent.trim() === '/') {
						// Move the cursor to the slash in the next node
						tr.setSelection(TextSelection.create(tr.doc, insertPos + 2))
					} else {
						// Otherwise, perform the normal "insert slash" logic
						if (currentNodeIsEmptyParagraph) {
							// If the current node is an empty paragraph, insert slash there
							tr.insertText('/', currentNodePos, currentNodePos + 1)
							// Move cursor to right after the slash
							tr.setSelection(TextSelection.create(tr.doc, currentNodePos + 2))
						} else {
							// Insert a new paragraph with a slash after current node
							const slashParagraph = state.schema.nodes.paragraph.create(null, [state.schema.text('/')])
							tr.insert(insertPos, slashParagraph)
							// Move cursor to right after the slash in the new paragraph
							tr.setSelection(TextSelection.create(tr.doc, insertPos + 2))
						}
					}

					dispatch(tr)
					return true
				})
				.run()
		}
	}, [currentNode, currentNodePos, editor])

	return {
		resetTextFormatting,
		duplicateNode,
		copyNodeToClipboard,
		deleteNode,
		handleAdd,
	}
}

export default useContentItemActions
