export function splitHTMLInto500WordChunks(htmlText: string, maxWords = 500) {
	// Split on `</p>` to isolate paragraphs
	// (This is a naive approach; for more complex HTML, consider a parser.)
	const rawParagraphs = htmlText
		// Split by closing p-tags
		.split(/<\/p>/i)
		// Trim each piece and re-add the `</p>` (if it wasn't empty)
		.map((para) => para.trim())
		.filter(Boolean)
		.map((para) => (para.endsWith('</p>') ? para : para + '</p>'))

	let chunks = []
	let currentChunkParagraphs: string[] = []
	let currentWordCount = 0

	for (const paragraph of rawParagraphs) {
		// Count words in this paragraph (strip any HTML tags first)
		const paragraphText = paragraph.replace(/<[^>]+>/g, '').trim()
		const paragraphWordCount = paragraphText.split(/\s+/).filter(Boolean).length

		// If adding this paragraph would exceed maxWords, push the current chunk
		// and start a new one
		if (currentWordCount + paragraphWordCount > maxWords) {
			// Push the current chunk as one piece
			chunks.push(currentChunkParagraphs.join(''))

			// Start a new chunk with this paragraph
			currentChunkParagraphs = [paragraph]
			currentWordCount = paragraphWordCount
		} else {
			// Append this paragraph to the current chunk
			currentChunkParagraphs.push(paragraph)
			currentWordCount += paragraphWordCount
		}
	}

	// Push the last chunk if it has content
	if (currentChunkParagraphs.length > 0) {
		chunks.push(currentChunkParagraphs.join(''))
	}

	return chunks
}
