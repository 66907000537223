import { useAtom } from 'jotai'
import { accountModalAtom } from '../../Pages/Create/Create'
import { CONSTANTS } from '../../constants'
import { useProcessedSubtopics } from '../../Pages/Create/hooks'
import { FEATURE_FLAGS, useFreeWordLimit } from '../../hooks/featureFlags'
import { selectDocumentState } from '../../redux/docSlice'
import { DocumentState } from '../../redux/types'
import { useSelector } from 'react-redux'
import Button from '../Buttons'
import { FaCheckCircle } from 'react-icons/fa'
import { HiSparkles } from 'react-icons/hi2'
import { useFeatureFlagVariantKey } from 'posthog-js/react'

const DocumentBlur = ({ docId }: { docId: string }) => {
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const [, setAccountModalState] = useAtom(accountModalAtom)
	const freeWordLimit = useFreeWordLimit()
	const processedSubtopics = useProcessedSubtopics()
	const blurV3FF = useFeatureFlagVariantKey(FEATURE_FLAGS.BLUR_V3)

	return (
		<div
			className="overflow-hidden relative md:h-96 h-[500px]"
			onClick={() => {
				setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: true }))
			}}
		>
			<div className="blur-sm pl-1">
				{documentState.userWriterSelections?.wordCount > CONSTANTS.TOPIC_GENERATION_BREAKPOINT && (
					<div className="text-3xl leading-loose">
						{freeWordLimit > 250 ? processedSubtopics[2] : processedSubtopics[1]}
					</div>
				)}
				<div className="text-xl leading-loose mt-2 h-48 select-none">
					Lorem ipsum odor amet, consectetuer adipiscing elit. Facilisis semper velit curabitur dignissim augue quisque
					suspendisse aenean. Ipsum eu proin commodo a dolor ad maecenas et. Sed aptent purus platea leo est conubia
					dictum nascetur? Vivamus dapibus consequat nibh pulvinar aenean. Non vestibulum turpis gravida morbi porta
					hendrerit fusce. Vivamus dapibus consequat nibh pulvinar aenean. Non vestibulum turpis gravida morbi porta
					hendrerit fusce. Vivamus dapibus consequat nibh pulvinar aenean. Non vestibulum turpis gravida morbi porta
					hendrerit fusce.
				</div>
			</div>
			{blurV3FF === 'test' ? (
				<div className="bg-secondary w-full absolute bottom-0 flex items-center justify-between md:gap-5 gap-7 px-7 py-7 rounded flex-wrap md:flex-nowrap">
					<div className="flex flex-col gap-6">
						<div className="text-2xl font-semibold">
							Unlock your full essay and go <span className="fancy-font font-normal">AI-undetectable</span>
						</div>
						<div className="grid md:grid-cols-[3fr,4fr] md:gap-3 gap-4 grid-cols-1">
							{[
								'Unlimited 2500 word essays',
								'Advanced Essay Options and AI Tools',
								'AI Humanization and Detection',
								'Early access to upgraded models and beta features',
							].map((_, i) => (
								<div key={i} className="flex gap-3 items-center">
									<div className="text-success-dark">
										<FaCheckCircle />
									</div>
									<div className="font-semibold">{_}</div>
								</div>
							))}
						</div>
					</div>
					<div className="md:text-left text-center w-full md:w-auto">
						<Button
							type="default"
							className={`border border-primary px-9  text-white font-semibold border-dotted shrink-0 py-3`}
						>
							<div className="flex items-center gap-6">
								<div className="w-0">
									<HiSparkles />
								</div>
								Upgrade
							</div>
						</Button>
					</div>
				</div>
			) : (
				<div className="bg-secondary w-full absolute bottom-0 flex items-center justify-center flex-col gap-3 py-9 px-3">
					<div className="text-2xl font-semibold text-center">
						Unlock your full essay and go <span className="fancy-font font-normal">AI-undetectable</span>
					</div>
					<div className="text-lg text-gray-700 max-w-[650px] text-center">
						Get access to your entire essay, unlimited humanizing, unlimited AI generations, and autocomplete.
					</div>
					<Button
						type="primary"
						className={`border border-primary px-9 py-2 bg-primary text-white font-semibold border-dotted`}
					>
						Upgrade Now
					</Button>
				</div>
			)}
		</div>
	)
}

export default DocumentBlur
