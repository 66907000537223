import { useDispatch, useSelector } from 'react-redux'
import { useDocId } from '../../../hooks/docID'
import { DocumentState } from '../../../redux/types'
import { selectDocumentState, setReferences } from '../../../redux/docSlice'
import { formatReference } from '../../../helpers/strings'
import { FaCheck } from 'react-icons/fa'
import { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { useAuth } from '../../../contexts/Auth'
import { Skeleton } from '@mui/material'
import { LuChevronsDown } from 'react-icons/lu'
import useLoadingDots from '../../../hooks/loadingDots'
import { FaPlus } from 'react-icons/fa6'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../../hooks/featureFlags'

const ReferencesSelection = ({
	expandReferences,
	isExpanded = false,
}: {
	expandReferences?: () => void
	isExpanded?: boolean
}) => {
	const { currentUser } = useAuth() as any
	const dispatch = useDispatch()
	const docId = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false)
	const scrollContainerRef = useRef(null)
	const loadingDots = useLoadingDots(documentState.generatingReferences)
	const selectReferencesTopBarFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SELECT_REFERENCES_TOP_BAR)

	const handleScroll = () => {
		const container = scrollContainerRef.current
		if (container) {
			// @ts-ignore
			const isAtBottom = container.scrollHeight - container.scrollTop === container.clientHeight
			if (isAtBottom) {
				setHasScrolledToBottom(true)
			}
		}
	}

	useEffect(() => {
		const container = scrollContainerRef.current

		if (container) {
			// @ts-ignore
			container.addEventListener('scroll', handleScroll)
		}

		return () => {
			// Clean up the event listener when the component unmounts
			if (container) {
				// @ts-ignore
				container.removeEventListener('scroll', handleScroll)
			}
		}
	}, [scrollContainerRef])

	const references = useMemo(() => {
		return documentState.allReferences.map((ref, index) => ({
			...ref,
			selected: _.findIndex(documentState.references, ref) !== -1,
		}))
	}, [documentState.allReferences, documentState.references])

	const toggleReference = async (index: number) => {
		let newReferences = [...(documentState?.references ?? [])]
		let ref = references[index]
		// @ts-ignore
		delete ref.selected
		const refIndex = _.findIndex(newReferences, references[index])
		if (refIndex === -1) {
			newReferences.push(references[index])
		} else {
			newReferences.splice(refIndex, 1)
		}
		dispatch(
			setReferences({
				docID: docId,
				references: newReferences,
			})
		)
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				id: docId,
				references: newReferences,
			}),
		}
		fetch(process.env.REACT_APP_API_ROOT + '/document/update/', requestOptions).catch((err) => {
			console.error('Error creating source:', err)
		})
	}

	if (!documentState || !documentState.userWriterSelections.refStyle) {
		return null
	}

	if (documentState.generatingReferences) {
		return (
			<div className="relative">
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-gray-400 shadow px-2 py-1 rounded-lg bg-white font-semibold flex items-center gap-1 z-10 mt-0.5 text-center">
					Finding references{loadingDots}
				</div>
				<div className="flex flex-col gap-1 w-full h-64">
					{[1, 2, 3, 4].map((i) => (
						<Skeleton key={i} variant="text" width="100%" height={100} />
					))}
				</div>
			</div>
		)
	}

	if (selectReferencesTopBarFF === 'test') {
		return (
			<div className="relative">
				<div className="flex justify-between items-center relative bottom-2">
					<div className="text-sm font-semibold">{documentState?.references.length} References Selected</div>
					{expandReferences && (
						<div className="font-semibold cursor-pointer text-sm" onClick={expandReferences}>
							<FaPlus className="inline-block mb-1 mr-1.5" />
							View all
						</div>
					)}
				</div>
				<div
					className={`flex flex-col gap-6 overflow-y-auto relative border-t border-gray-300 pt-4 ${
						isExpanded ? 'max-h-[500px]' : 'max-h-72'
					}`}
					ref={scrollContainerRef}
				>
					{references.map((ref, index) => (
						<div key={index} className="flex gap-3">
							<div
								className={`cursor-pointer border border-gray-800 w-6 h-6  shrink-0 flex justify-center items-center rounded mt-1.5 ${
									ref.selected ? 'bg-secondary' : ''
								}`}
								onClick={() => toggleReference(index)}
							>
								{ref.selected && <FaCheck size={12} />}
							</div>
							<div className="leading-loose primary-font break-all">
								{formatReference(documentState.userWriterSelections.refStyle!, ref, index)}
							</div>
						</div>
					))}
				</div>
				{!hasScrolledToBottom && (
					<div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 border border-gray-400 shadow px-2 py-1 rounded-lg bg-white font-semibold flex items-center gap-1">
						<LuChevronsDown size={18} />
						Scroll for more
					</div>
				)}
			</div>
		)
	}

	return (
		<div className="relative">
			<div className="flex flex-col gap-6 max-h-64 overflow-y-auto relative" ref={scrollContainerRef}>
				{references.map((ref, index) => (
					<div key={index} className="flex gap-3">
						<div
							className={`cursor-pointer border border-gray-800 w-6 h-6  shrink-0 flex justify-center items-center rounded mt-1.5 ${
								ref.selected ? 'bg-secondary' : ''
							}`}
							onClick={() => toggleReference(index)}
						>
							{ref.selected && <FaCheck size={12} />}
						</div>
						<div className="text-lg leading-loose primary-font break-all">
							{formatReference(documentState.userWriterSelections.refStyle!, ref, index)}
						</div>
					</div>
				))}
			</div>
			{!hasScrolledToBottom && (
				<div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 border border-gray-400 shadow px-2 py-1 rounded-lg bg-white font-semibold flex items-center gap-1">
					<LuChevronsDown size={18} />
					Scroll for more
				</div>
			)}
		</div>
	)
}

export default ReferencesSelection
