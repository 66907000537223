import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../Buttons'
import Dropdown from '../Dropdown/Dropdown'
import { selectShowNav, selectUser } from '../../redux/systemSlice'
import { selectDocumentState, setContent, setIsOnSubtopicsStep } from '../../redux/docSlice'
import { useAuth } from '../../contexts/Auth'
import Loader from '../Loader'
import { CONSTANTS, DOC_TEMPLATES } from '../../constants'
import './_AddMore.scss'
import { htmlToString } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import ReplayIcon from '@mui/icons-material/Replay'
import AddIcon from '@mui/icons-material/Add'
import { useCookieUser } from '../../hooks/cookieUser'
import Revert from '../Modals/Revert'
import { useAtom } from 'jotai'
import { accountModalAtom } from '../../Pages/Create/Create'
import WordCount from '../Navbar/WordCount'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import useIsPremium from '../../hooks/useIsPremium'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import { LuRotateCcw } from 'react-icons/lu'
import AddMenu from '../Document/AddMenu'
import { Editor } from '@tiptap/core'

type AddMoreProps = {
	type: string | undefined
	docID: string
	isFixed: boolean
	editor: Editor
}

function AddMore({ type, docID, isFixed, editor }: AddMoreProps) {
	const { userIDCookie } = useCookieUser()
	const [accountModalState, setAccountModalState] = useAtom(accountModalAtom)
	const [addMoresliderValue, setAddMoresliderValue] = useState(CONSTANTS.ADD_MORE_MIN)
	const insertTextPlusButtonFF = useFeatureFlagVariantKey(FEATURE_FLAGS.INSERT_TEXT_PLUS_BUTTON_V3)
	const closeAddMore = useRef(null)
	const user = useSelector(selectUser)
	const dispatch = useDispatch()
	const showNav = useSelector(selectShowNav)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const { currentUser } = useAuth() as any
	const [addMoreOpen, setAddMoreOpen] = useState(false)
	const [revertModalOpen, setRevertModalOpen] = useState(false)
	const [isTypingAddMore, setIsTypingAddMore] = useState(false)
	const posthog = usePostHog()
	const isPremium = useIsPremium()
	const navBtnStylingFF = useFeatureFlagVariantKey(FEATURE_FLAGS.NAV_BUTTON_STYLING)
	const addMoreButtonV2FF = useFeatureFlagVariantKey(FEATURE_FLAGS.ADD_MORE_BUTTON_V2)

	async function generateAddMore() {
		posthog.capture('add-more-generated')
		setIsTypingAddMore(true)
		const token = currentUser ? await currentUser.getIdToken() : ''

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				prompt: htmlToString(documentState.content.split(' ').slice(-100).join(' ')),
				userId: user.id ?? userIDCookie,
				docId: docID,
				wordCount: addMoresliderValue,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/add-more/', requestOptions)
			.then((res) => res.json())
			.then((response: { addMore: string }) => {
				if (response.addMore) {
					dispatch(setContent({ docID: docID, content: documentState.content + response.addMore }))
					setIsTypingAddMore(false)
				}
			})
	}

	async function getAddMore() {
		if (!isPremium) {
			setAccountModalState({ ...accountModalState, subscriptionModalOpen: true })
			return
		}

		const token = currentUser ? await currentUser.getIdToken(true) : undefined
		if (!token) {
			setAccountModalState({ ...accountModalState, loginModalOpen: true })
			return
		}
		generateAddMore()
	}

	const addMoreContent =
		addMoreButtonV2FF === 'test' ? (
			<AddMenu editor={editor} appendAtEnd />
		) : (
			<>
				<p className="dropdownHeader mb-2">Choose # of words to auto-complete:</p>
				<input
					type="range"
					min={CONSTANTS.ADD_MORE_MIN}
					max={CONSTANTS.ADD_MORE_MAX}
					className={'slider'}
					value={addMoresliderValue}
					onChange={(e) => {
						setAddMoresliderValue(parseInt(e.target.value))
					}}
				/>
				<div className="addMoreBottomContainer">
					<h6 className="addMoreSliderValue header-sm">
						<span className={'value'}>{addMoresliderValue}</span>
						<span className={'units fancy-font'}>words</span>
					</h6>
					{documentState.isGenerating || isTypingAddMore ? (
						<Loader small />
					) : (
						<Button type="secondary" onClick={getAddMore} className="w-100">
							<span>Generate</span>
						</Button>
					)}
				</div>
			</>
		)

	return (
		<>
			<Revert
				docID={docID}
				closeModal={() => {
					setRevertModalOpen(false)
				}}
				isOpen={revertModalOpen}
			/>
			<div
				className={`${insertTextPlusButtonFF === 'test' ? 'md:hidden' : ''} flex addMoreContainer mt-6  ${
					isFixed ? 'fixed' : 'sticky'
				} ${showNav ? 'visible ' : ''}`}
			>
				<div className="flex gap-4 justify-between w-full items-center">
					<div className="flex gap-1">
						{insertTextPlusButtonFF !== 'test' &&
							documentState.content &&
							!documentState.isGenerating &&
							!documentState.isTypingEssay && (
								<span
									className={`addMore ${addMoreOpen ? ' open ' : ''}`}
									onClick={() => {
										!documentState.isTypingEssay && !isTypingAddMore && setAddMoreOpen(!addMoreOpen)
										posthog.capture('add-more-clicked')
									}}
									ref={closeAddMore}
								>
									<AddIcon />
								</span>
							)}

						{!documentState.isGenerating &&
							!documentState.isTypingEssay &&
							!isTypingAddMore &&
							type !== DOC_TEMPLATES.Blank && (
								<>
									{insertTextPlusButtonFF === 'test' ? (
										<Button
											type={navBtnStylingFF === 'test' ? 'outline' : 'tertiary'}
											onClick={() => {
												setRevertModalOpen(true)
												dispatch(setIsOnSubtopicsStep({ docID: docID, isOnSubtopicsStep: false }))
											}}
										>
											<div className="flex items-center gap-2 text-sm">
												Restart
												<LuRotateCcw />
											</div>
										</Button>
									) : (
										<span
											className="revert"
											onClick={() => {
												setRevertModalOpen(true)
												dispatch(setIsOnSubtopicsStep({ docID: docID, isOnSubtopicsStep: false }))
											}}
										>
											<ReplayIcon />
										</span>
									)}
								</>
							)}
					</div>
					<div className="flex gap-4 sm:hidden">
						<WordCount />
					</div>
				</div>

				{/* {documentState?.content && !documentState.isGenerating && documentState.isTypingAddMore && (
				<Basic
					onClick={() => {
						if (addMoreReader) addMoreReader.cancel()
					}}
					type="tertiary"
					CTA={<span>Stop autocomplete</span>}
					className="abortButton"
				/>
			)} */}
				<Dropdown
					open={addMoreOpen}
					setOpen={(val) => {
						setAddMoreOpen(val)
					}}
					body={addMoreContent}
					closeButton={closeAddMore}
					className={`addMoreDropdown absolute ${
						addMoreButtonV2FF === 'test' ? 'md:top-[-245px] top-[-300px]' : 'top-[-135px] md:w-[300px] py-2 pr-3 pl-2'
					} left-0 bg-white`}
				/>
			</div>
		</>
	)
}

export default AddMore
